import styled from 'styled-components';

import { MiniBadge } from '@xing-com/badge';
import { SGapCol } from '@xing-com/crate-jobs-components-columns';
import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Link as BaseLink } from '@xing-com/link';
import { Marker as BaseMarker } from '@xing-com/marker';
import { spaceM, spaceXS, xdlColorText } from '@xing-com/tokens';
import { BodyCopy, Headline, Meta } from '@xing-com/typography';

export const Link = styled(BaseLink)`
  color: ${xdlColorText};

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const Article = styled.article`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const Content = styled(SGapCol)`
  width: 100%;
  margin-left: ${spaceM};

  @media ${mediaConfined} {
    gap: ${spaceM};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const Marker = styled(BaseMarker)`
  width: fit-content;
`;

export const Title = styled(Headline)`
  ${lineClamp(2)};
  word-break: break-word;
`;

export const City = styled(BodyCopy)`
  ${lineClamp(1)};
  word-break: break-all;
`;

export const Company = styled(BodyCopy)`
  ${lineClamp(1)};
  word-break: break-all;
`;

export const BottomCol = styled(SGapCol)`
  @media ${mediaConfined} {
    gap: ${spaceM};
  }
`;

export const Date = styled(Meta)`
  display: flex;
  align-items: center;
`;

export const Notification = styled(MiniBadge)`
  margin-right: ${spaceXS};
`;
