import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AnimatedSparkles } from '@xing-com/crate-jobs-components-animated-sparkles';
import { useTimedCallback } from '@xing-com/crate-jobs-hooks';
import { spaceXL, spaceXS, xdlColorTextSecondary } from '@xing-com/tokens';
import { Meta } from '@xing-com/typography';

const VALUE = 'Finding key matches in the job details...';
const ANIMATION_TYPE_SPEED_MS = 18;
const THREE_DOTS_TYPE_SPEED_MS = 500;

export const MatchingReasonHighlightLoading: React.FC = () => {
  const timed = useTimedCallback({ speed: ANIMATION_TYPE_SPEED_MS });

  const ref = useRef<HTMLParagraphElement | null>(null);
  const charIndexRef = useRef(0);

  const type = (): void => {
    if (!ref.current) {
      return;
    }

    ref.current.innerText = VALUE.slice(0, charIndexRef.current);

    if (charIndexRef.current < VALUE.length) {
      ++charIndexRef.current;
    } else {
      charIndexRef.current -= 3;
    }
    timed.run(type, {
      delay:
        charIndexRef.current <= VALUE.length - 3
          ? ANIMATION_TYPE_SPEED_MS
          : THREE_DOTS_TYPE_SPEED_MS,
    });
  };

  useEffect(() => {
    const initialTypeTimeout = setTimeout(() => {
      type();
      // Run once icon animation has finished
    }, 500);

    return () => {
      clearTimeout(initialTypeTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MRContainer>
      <AnimatedSparkles color={xdlColorTextSecondary} shortAnimation />
      <Meta
        noMargin
        ref={ref}
        color={xdlColorTextSecondary}
        data-testid="highlight-container"
      >
        {' '}
      </Meta>
    </MRContainer>
  );
};

const MRContainer = styled.div`
  display: flex;
  gap: ${spaceXS};
  align-items: center;
  min-height: ${spaceXL};
`;
