import type { MouseEventHandler } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AnimatedSparkles } from '@xing-com/crate-jobs-components-animated-sparkles';
import { spaceXS, xdlColorText } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const BOLD_REGEX = /(\*\*|__)(.*?)\1/g;
const ITALIC_REGEX = /(\*|_)((?!\1).*?)\1/g;

type Part = {
  type: 'text' | 'bold' | 'italic';
  content: string;
};

const parseParts = (
  text: string,
  type: 'bold' | 'italic',
  regexp: RegExp
): Part[] => {
  const parts: Part[] = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null = null;

  while ((match = regexp.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push({
        type: 'text',
        content: text.substring(lastIndex, match.index),
      });
    }
    parts.push({ type, content: match[2] });
    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    parts.push({ type: 'text', content: text.substring(lastIndex) });
  }

  return parts;
};

const parseBoldParts = (text: string): Part[] =>
  parseParts(text, 'bold', BOLD_REGEX);

const parseItalicParts = (parsedParts: Part[] = []): Part[] => {
  const finalParts: Part[] = [];

  for (const part of parsedParts) {
    if (part.type !== 'text') {
      finalParts.push(part);
      continue;
    }

    finalParts.push(...parseParts(part.content, 'italic', ITALIC_REGEX));
  }

  return finalParts;
};

const partsToHTML = (parts: Part[]): string =>
  parts.reduce((agg, part) => {
    switch (part.type) {
      case 'bold':
        return `${agg}<strong>${part.content}</strong>`;
      case 'italic':
        return `${agg}<em>${part.content}</em>`;
      default:
        return `${agg}${part.content}`;
    }
  }, '');

type Props = {
  children: string;
  expand?: boolean;
  onExpand?: () => void;
};

export const Markdown: React.FC<Props> = ({ children, expand, onExpand }) => {
  const shouldExpand = useRef(false);
  const markdownRef = useRef<HTMLParagraphElement>(null);
  const parts = useMemo(() => {
    if (!children) {
      return [];
    }

    return parseItalicParts(parseBoldParts(children));
  }, [children]);

  const { formatMessage } = useIntl();
  const moreText = formatMessage({ id: 'BUTTON_MORE' }).toLocaleLowerCase();

  useEffect(() => {
    if (parts.length === 0 || !markdownRef.current) {
      return;
    }

    const extra = `... ${moreText}`;
    // When markdown is first rendered, it will include the icons that are
    // rendered in the return function
    const icons = markdownRef.current.innerHTML;

    // Before we start checking which how much text do we need to strip, let's
    // check if by rendering the content takes less than two lines
    markdownRef.current.innerHTML = `${icons}${partsToHTML(parts)}`;
    if (markdownRef.current.scrollHeight <= 48) {
      // Does not require more than 2 lines, so we there's no need to do any check!
      return;
    }

    shouldExpand.current = true;

    let partIndex = 1;
    let stop = false;
    // Since the content can be in bold, meaning that it would have a different
    // width from the regular text, we need to take the parts into account.
    // Therefore, we trim the content of the last part and we render it taking
    // into account it's type
    while (partIndex < parts.length && !stop) {
      const last = parts[parts.length - partIndex];
      const slicedParts = parts.slice(0, parts.length - partIndex);

      for (
        let j = last.content.length - 1;
        markdownRef.current.scrollHeight > 48 && j >= 0;
        j--
      ) {
        const text = `${icons}${partsToHTML([
          ...slicedParts,
          { ...last, content: last.content.slice(0, j * extra.length) },
        ])}`;
        // Update the element and check it's new height
        markdownRef.current.innerHTML = `${text}${extra}`;
      }

      // If we already are under two lines, we can stop
      stop = markdownRef.current.scrollHeight <= 48;
      partIndex++;
    }
    console.log(markdownRef.current.innerHTML);
  }, [parts, moreText, markdownRef]);

  if (parts.length === 0) {
    return undefined;
  }

  const handleOnClick: MouseEventHandler = (e): void => {
    if (!shouldExpand.current) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    onExpand?.();
  };

  // We need to render the sparkles container as a span, since the animation is
  // rendered inside a p and div cannot be inside a p
  const icon = (
    <ReasonsIcon>
      <AnimatedSparkles sparklesAs="span" color={xdlColorText} shortAnimation />
    </ReasonsIcon>
  );

  if (expand) {
    return (
      <BodyCopy key="auto" noMargin>
        {icon}
        {/* dangerouslySetInnerHTML is safe to use here */}
        <span dangerouslySetInnerHTML={{ __html: partsToHTML(parts) }} />
      </BodyCopy>
    );
  }

  // In order to be able to render the svg inline with the text, we need to
  // render it inside the paragraph, despite not being web standard
  return (
    <BodyCopy key="modified" ref={markdownRef} onClick={handleOnClick} noMargin>
      {icon}
      {/* The content is rendered here in the useEffect, by updating innerHTML */}
    </BodyCopy>
  );
};

const ReasonsIcon = styled.span`
  display: inline-flex;
  vertical-align: middle;
  margin-right: ${spaceXS};
`;
