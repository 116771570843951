import { useState, useEffect, useRef } from 'react';
import type React from 'react';
import styled from 'styled-components';

import { ANIMATION_DURATION } from './constants';

export const DynamicHeightContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [height, setHeight] = useState('auto');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = (): void => {
      if (!containerRef.current) {
        return;
      }

      const containerHeight = containerRef.current.scrollHeight;
      // We need to force the height of the container based on steps as
      // non-retina displays may have issues calculating the resulting height.
      // It's quite a strange issue
      setHeight(`${Math.ceil(containerHeight / 24) * 24}px`);
    };

    // Update the initial height
    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [children]);

  return (
    <Container $height={height} ref={containerRef}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ $height: number | string }>`
  height: ${({ $height }) => $height};
  transition: height ${ANIMATION_DURATION}ms ease-out;
  overflow: hidden;
`;
